<template>
  <v-container class="pa-0" fluid style="height: calc(100dvh - 56px)">
    <div id="map" class="mapa"></div>
    <v-snackbar :timeout="timeout" multi-line pill elevation="10" centered v-model="isLoading">
      <div>
        Carregando localização
      </div>
      <div>
        <b class="recarregar" @click="refreshPage">Caso demore muito recarregue a página clicando aqui. </b>
      </div>
    </v-snackbar>
    <div class="d-flex justify-center mt-2 gps-btn-container">
      <v-btn class="gps-btn" bottom @click="getUserLocation()" x-large elevation="2" color="rgba(0, 0, 0, 0.70)" fab
        style="color: white">
        <v-icon> mdi-crosshairs </v-icon>
      </v-btn>
    </div>
    <v-btn elevation="4" icon x-large class="style-change mt-1 ml-1"
      @click="changeStyle"><v-icon>mdi-satellite-variant</v-icon></v-btn>
  </v-container>
</template>
<script>
import mapboxgl from "mapbox-gl";

export default {
  name: "CampoMapa",
  data() {
    return {
      map: null,
      timeout: 500000,
      accessToken: process.env.VUE_APP_ACCESS_TOKEN,
      mapStyle: process.env.VUE_APP_MAP_STYLE_URL,
      styleSatellite: "mapbox://styles/mapbox/satellite-streets-v11",
      center: [-43.12725, -22.81692],
      isGpsOn: false,
      zoom: 10,
      isLoading: false,
      coordCampo: [],
      coord: {
        lat: 0,
        lng: 0
      },
      watchId: null,
      geolocate: null,
      gpsState: null,
    }
  },
  mounted() {
    mapboxgl.accessToken = this.accessToken
    this.map = new mapboxgl.Map({
      container: 'map',
      style: this.mapStyle,
      center: this.center,
      zoom: this.zoom
    });


    this.geolocate = new mapboxgl.GeolocateControl({
      positionOptions: {
        enableHighAccuracy: true
      },
      trackUserLocation: true
    });

    this.map.on("load", (x) => {
      let btn = document.querySelector(".mapboxgl-ctrl-geolocate")
      btn.classList.add("pa-9")
      let topRightControl = document.querySelector(".mapboxgl-ctrl-top-right");
      if (topRightControl) {
        topRightControl.style.zIndex = 5;
      }
    })
    this.map.addControl(this.geolocate);
    this.geolocate.on('geolocate', (e) => {
      this.coord.lat = e.coords.latitude;
      this.coord.lng = e.coords.longitude;
    });

    let geoCoderCtrl = this.geolocate._container

    geoCoderCtrl.style.backgroundImage = "url('https://res.cloudinary.com/dvmprway0/image/upload/v1686779702/gps_m2xrhx.png')"
    geoCoderCtrl.style.backgroundSize = "cover";
    geoCoderCtrl.style.backgroundColor = "#e7e3e3";
    geoCoderCtrl.style.position = "absolute";
    geoCoderCtrl.style.right = "0px";
    geoCoderCtrl.style.top = "60px";
    geoCoderCtrl.style.height = "80px";
    geoCoderCtrl.style.width = "80px";

    // geoCoderCtrl.style.padding = '20px'
    geoCoderCtrl.style.borderRadius = '50%'

    this.geolocate.on('geolocate', (x) => {
      this.gpsState = this.map._controls[2]._watchState
    })
    geoCoderCtrl.addEventListener('click', this.test)


  },
  watch: {
    gpsState() {
      let geoCoderCtrl = this.geolocate._container
      if (this.gpsState == "OFF") {
        geoCoderCtrl.style.backgroundColor = "#e7e3e3";
        this.isLoading = false;
      } else if (this.gpsState == "WAITING_ACTIVE") {
        geoCoderCtrl.style.backgroundColor = "yellow";
        this.isLoading = true;
      } else if (this.gpsState == "ACTIVE_LOCK") {
        geoCoderCtrl.style.backgroundColor = "rgba(56,216,88,0.6)";
        this.isLoading = false;

      }
    },
  },
  methods: {
    refreshPage() {
      window.location.reload()
    },
    async changeStyle() {
      let styleID = 'satellite-streets-v12';      
      let x = this.map.getStyle();
      let isBasic = x.name == 'Basic' ? true : false;
      const { data: newStyle } = await axios.get(
        `https://api.mapbox.com/styles/v1/${isBasic ? 'mapbox' : 'deolhonabaia'}/${isBasic ? styleID : 'ckz63btci000w14nn74e59vnz'}?access_token=${this.accessToken}`
      )
      await this.map.setStyle(newStyle);
    },
    test() {
      this.gpsState = this.map._controls[2]._watchState
    },
    updateCoordinates(position) {
      this.coord.lat = position.coords.latitude;
      this.coord.lng = position.coords.longitude;
    },
    getUserLocation() {
      if (this.gpsState == "ACTIVE_LOCK") {
        this.coordCampo[0] = this.coord.lat;
        this.coordCampo[1] = this.coord.lng;

        this.$router.push({
          path: '/app/add',
          query: { data: JSON.stringify(this.coordCampo) }
        });
        return
      }
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((data) => {
          this.coordCampo[0] = data.coords.longitude;
          this.coordCampo[1] = data.coords.latitude;
          this.isLoading = false;
          this.$router.push({
            path: '/app/add',
            query: { data: JSON.stringify(this.coordCampo) }
          });
        });
      }
    },
    beforeDestroy() {
      if (this.map) {
        this.map.remove();
      }
    }


  }
}
</script>
<style scoped>
.recarregar {
  text-decoration: underline;
  cursor: pointer;
}

.mapa {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
}

.gps-btn-container {
  position: absolute;
  bottom: 8vh;
  left: 50%;
  transform: translateX(-50%);
}

.gps-btn {
  padding: 50px;
}

.mapboxgl-ctrl-group>button {
  background-color: red !important;
}

.mapboxgl-ctrl-geolocate {
  background-color: red !important;
  padding: 16px !important
}

.style-change {
  position: absolute;
  z-index: 5;
  top: 80px;
  left: 5px;
  width: 80px !important;
  height: 80px !important;
  background: #e7e3e3;
  border-radius: 50%;
  box-shadow: 6px 6px 10px rgba(0, 0, 0, 0.1),
    -6px -6px 10px rgba(255, 255, 255, 0.5);
  transition: box-shadow 0.3s ease;
  cursor: pointer;
}

.style-change:hover {
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.4),
    -4px -4px 8px rgba(255, 255, 255, 0.7);
}

.style-change .icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 24px;
  color: #888888;
}

.style-change .icon:hover {
  color: #333333;
}
.v-btn--icon.v-size--x-large .v-icon, .v-btn--fab.v-size--x-large .v-icon{
  font-size: 42px !important;
}
</style>